@use "../destail";
@use "../base";
@use "../mediaQuery"as mq;

$spacing-unit: 0.5rem;
$spacing-1: $spacing-unit; //8
$spacing-2: $spacing-unit * 2; //16 
$spacing-3: $spacing-unit * 3; //24
$spacing-4: $spacing-unit * 5; //40
$spacing-5: $spacing-unit * 8; //64
$spacing-6: $spacing-unit * 13; //104
$spacing-7: $spacing-unit * 21; //168
$spacing-8: $spacing-unit * 34; //272

$font-scale-unit: 1rem;
$font-size-1: $font-scale-unit * 8 / 10; //12.8
$font-size-2: $font-scale-unit * 8 / 9; //14.222
$font-size-3: $font-scale-unit * 8 / 8; //16
$font-size-4: $font-scale-unit * 8 / 7; //18.286
$font-size-5: $font-scale-unit * 8 / 6; //21.333
$font-size-6: $font-scale-unit * 8 / 5; //25.6
$font-size-7: $font-scale-unit * 8 / 4; //32
$font-size-8: $font-scale-unit * 8 / 3; //42.667
$font-size-9: $font-scale-unit * 8 / 2; //64
$font-size-10: $font-scale-unit * 8; //128

$font-size-body: $font-size-3;
$font-size-h1: $font-size-7;
$font-size-h2: $font-size-6;
$font-size-h3: $font-size-4;

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'EB Garamond', 'Zen Old Mincho', serif;
}

#root {
    white-space: pre-line;
}

%gridBase {
    display: grid;
}

.gridBase--8-4 {
    @extend %gridBase;
    grid-template-columns: repeat(8, 1fr);
}

.header {
    $this: &;
    // position: fixed;
    width: 100%;
    padding: $spacing-3 $spacing-4;

    &__inner {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    &__logo {
        width: 4.5rem;
        flex-shrink: 0;

        img {
            height: 100%;
        }
    }

    &__navi {
        padding: 0 $spacing-5;
        display: flex;
        justify-content: center;
        gap: 2rem;
        font-size: 1rem;
    }

    &__lang {
        position: absolute;
        right: 0;
        width: 120px;

        &.open>.header__langSelect {
            visibility: visible;
        }

        &.open>.header__lang__nav>span {
            transform: rotate(180deg);
        }

        &__nav {
            display: flex;
            width: 100%;
            justify-content: space-between;

            span {
                transition: .2s all ease-in-out;
            }
        }

        @include mq.mq-down(md) {
            top: 0;
            right: 0;
            width: 80px;
            font-size: 14px;
        }
    }

    &__langSelect {
        visibility: hidden;
        position: absolute;
        padding-top: $spacing-1;

        @include mq.mq-down(md) {
            background: #faf6ee;
            padding: 4px;
            margin-top: 8px;
            box-shadow: 0px 3px 10px rgb(111 93 28 / 20%);
        }
    }

    &__langSelectItem {
        padding: 8px 0;

        @include mq.mq-down(md) {
            font-size: 12px;
        }
    }

    &.--pc {
        @include mq.mq-down(md) {
            display: none;
        }
    }

    &.--sp {
        display: none;
        margin: auto;
        padding: 16px;

        #{$this}__inner {
            flex-direction: column;
        }

        #{this}__logo {
            width: 4rem;
        }

        #{$this}__navi {
            margin-top: $spacing-3;
            padding: unset;
        }

        @include mq.mq-down(md) {
            display: block;
        }
    }
}

.FV {
    padding-left: 32px;
    padding-right: 32px;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mq.mq-down(md) {
        margin-top: $spacing-4;
    }

    &__copy {
        font-size: 5vw;
        line-height: 1.2;
        width: 11em;
        text-align: center;
        position: relative;
        overflow: hidden;

        @include mq.mq-down(lg) {
            font-size: 7vw;
        }

        @include mq.mq-down(md) {
            font-size: 11vw;
        }

        &__group {
            display: inline-block;
            margin-right: 0.2em;
            overflow: hidden;

            span {
                display: inline-block;
            }
        }

        &--ja {
            margin-top: $spacing-4;
            font-size: 1.25rem;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            &::before {
                content: "";
                display: block;
                width: 32px;
                height: 2px;
                margin-bottom: $spacing-4;
                background-color: #B22D35;
            }

            @include mq.mq-down(md) {
                font-size: 1rem;
            }
        }
    }
}

.products {
    $this: &;
    padding: $spacing-6 0;

    @include mq.mq-down(md) {
        padding: $spacing-3 $spacing-3;
    }

    .imageWrapper {
        display: flex;
        gap: 0;
        margin-top: $spacing-7;

        @include mq.mq-down(md) {
            margin-top: $spacing-5;
        }

        &.n1 {
            margin-left: -16px;
            grid-column: 1/6;
            margin-top: 0;

            @include mq.mq-down(md) {
                margin-right: -$spacing-3;
                grid-column: 1/8;
            }
        }

        &.n2 {
            grid-column: 3/8;

            @include mq.mq-down(md) {
                margin-right: -$spacing-3;
                grid-column: 2/9;
            }
        }

        &.n3 {
            align-items: center;
            grid-column: 2/9;

            @include mq.mq-down(md) {
                grid-column: 1/9;
                flex-direction: column;
            }

            #{$this}__image {
                width: 50%;
                padding: 0 3vw;
                text-align: center;

                @include mq.mq-down(md) {
                    width: 80%;
                    margin-right: auto;
                    padding: 0;
                }

                img {
                    max-width: 500px;
                }
            }
        }
    }

    &__image {
        width: 100%;
        flex-grow: 0;

    }

    &__text {
        padding: 0 3vw;
        width: 50%;
        flex-grow: 0;

        @include mq.mq-down(md) {
            width: 100%;
            margin-top: $spacing-6;
            padding: 0;
        }
    }

    &__title {
        font-size: $font-size-h1;
        line-height: 1.2;

        &__upper {
            font-size: $font-size-body;
            margin-bottom: $spacing-1;
        }
    }

    &__lead {
        margin-top: $spacing-5;
        font-size: $font-size-h2;
        line-height: 1.4;
        font-weight: 700;
    }

    &__body {
        margin-top: $spacing-3;
        font-size: $font-size-body;
        line-height: 2;

    }
}

.concept {
    padding: $spacing-7 32px;
    margin-left: 20vw;

    @include mq.mq-down(md) {
        padding: $spacing-5 $spacing-3;
        margin-left: unset;
    }

    &__inner {
        display: flex;

        @include mq.mq-down(md) {
            flex-direction: column;
        }
    }

    &__title {
        width: 20%;
        font-size: $font-size-h1;

        @include mq.mq-down(md) {
            width: 100%;
        }

        &__upper {
            font-size: $font-size-body;
            margin-bottom: $spacing-1;
        }
    }

    &__list {
        width: 80%;
        margin-left: 2rem;

        @include mq.mq-down(md) {
            width: 100%;
            margin-left: 0;
            margin-top: $spacing-5;
        }

        &__item {
            padding: $spacing-4 2rem;
            border-bottom: 1px solid #cbcbcb;

            &:first-of-type {
                border-top: 1px solid #cbcbcb;
            }
        }
    }

    &__subtitle {
        position: relative;
        font-size: $font-size-h2;
        line-height: 1;
        font-weight: 700;

        &__num {
            position: absolute;
            top: 0;
            left: -2rem;
            font-size: $font-size-1;
        }
    }

    &__body {
        margin-top: $spacing-2;
        line-height: 2;
    }
}

.feature {
    padding: $spacing-7 32px;
    background: #101010;
    color: #fff;

    @include mq.mq-down(md) {
        padding: $spacing-5 $spacing-3;
    }

    &__title {
        text-align: center;
        font-size: $font-size-h1;

        &__upper {
            font-size: $font-size-body;
            margin-bottom: $spacing-1;
        }
    }

    &__box {
        display: flex;
        margin: auto;
        margin-top: $spacing-6;
        justify-content: center;
        align-items: center;
        max-width: 80rem;
        gap: $spacing-4;

        @include mq.mq-down(md) {
            flex-direction: column;
        }

        &:nth-of-type(odd) {
            flex-direction: row-reverse;

            @include mq.mq-down(md) {
                flex-direction: column;
            }
        }
    }

    &__img {
        width: 50%;
        padding: 0 5vw;

        @include mq.mq-down(md) {
            width: 100%;
            padding: 0 1rem;
        }
    }

    &__text {
        width: 50%;
        padding: 0 5vw;

        @include mq.mq-down(md) {
            width: 100%;
            padding: 0 1rem;
        }
    }

    &__subtitle {
        position: relative;
        font-size: $font-size-h2;
        font-weight: 700;
        line-height: 1.3;

    }

    &__body {
        margin-top: $spacing-4;
        line-height: 2;
    }
}

.download {
    padding: $spacing-7 32px;

    @include mq.mq-down(md) {
        padding: $spacing-5 $spacing-3;
    }

    &__title {
        width: 100%;
        text-align: center;
        font-size: $font-size-h1;
        line-height: 1.2;
        text-align: center;
    }

    &__img {
        margin: auto;
        margin-top: $spacing-4;
        width: 128px;
    }

    &__name {
        font-size: $font-size-h3;
        margin-top: $spacing-3;
        text-align: center;
    }
}

.footer {
    margin: auto;
    padding: $spacing-4 16px;
    background: #101010;
    color: #fff;

    &__inner {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    &__logo {
        width: 4rem;
        flex-shrink: 0;

        img {
            height: 100%;
        }
    }

    &__navi {
        margin-top: $spacing-3;
        display: flex;
        justify-content: center;
        gap: 2rem;
        font-size: 1rem;

        @include mq.mq-down(md) {
            flex-direction: column;
            justify-content: center;
            gap: 1rem;
        }

        &__item {
            text-align: center;
            font-size: $font-size-1;
        }
    }
}

.reference {
    display: flex;
    gap: 15vw;
    padding: 128px 10vw;

    .ttl-pc {
        @include mq.mq-down(md) {
            display: none;
        }
    }

    .ttl-sp {
        display: none;
        margin-bottom: 64px;

        @include mq.mq-down(md) {
            display: block;
        }
    }

    @include mq.mq-down(md) {
        flex-direction: column-reverse;
        padding: 72px 6vw;
    }

    main {
        width: 70%;

        @include mq.mq-down(md) {
            width: 100%;
        }
    }

    aside {
        width: 30%;

        @include mq.mq-down(md) {
            width: 100%;
        }
    }

    &__heading {
        font-size: 32px;
        margin-bottom: 16px;

        @include mq.mq-down(md) {
            font-size: 24px;
        }
    }

    &__subheading {
        font-size: 18px;
        font-weight: bold;
        margin-top: 48px;
        padding-bottom: 18px;
        border-bottom: 1px solid #ddd;

        &.noborder {
            border: none;
        }

        @include mq.mq-down(md) {
            font-size: 18px;
        }
    }

    &__index {
        position: sticky;
        right: 0;
        top: 4rem;
        height: min-content;
        min-height: 574px;
        @include mq.mq-down(md) {
            position: relative;
            height: auto;
            top: 0;
        }
    }

    &__indexList {
        margin-top: 8px;

        &Item {
            font-size: 16px;
            padding: 8px 0;
            transition: transform 0.2s ease-in-out;

            &:hover {
                transform: translateX(8px);
            }

            @include mq.mq-down(md) {
                font-size: 14px;
            }
        }
    }

    &__movieItem {
        &+& {
            margin-top: 72px;
        }
    }

    &__embedContainer {
        width: 100%;
        padding-top: 56.56%;
        position: relative;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__footnote {
        margin-top: 48px;
        line-height: 1.65;

        &+& {
            margin-top: 24px;
        }

        @include mq.mq-down(md) {
            font-size: 14px;
        }
    }
}