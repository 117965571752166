@use "../destail";
@use "../mediaQuery" as mq;

body{
    background: #FAF6EE;
    color: #333;
}

.container{
    max-width: min(48em, 80vw);
    margin: auto;
    padding-top: 8rem;
    padding-bottom: 8rem;
    font-family: 'Zen Old Mincho', serif;
}

.page_header {
    h1{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        font-size: 2.5rem;
        margin-bottom: 3rem;
        span{
            font-size: 40%;
        }
        @include mq.mq-down(sm){
            font-size: 1.5rem;
        }
    }
}

.page_content{
    h2{
        font-size: 1.25rem;
        margin-top: 3em;
        margin-bottom: 0.5em;
        font-weight: bold;
        line-height: 1.4;
        @include mq.mq-down(sm){
            font-size: 1rem;
        }
    }
    p, li{
        line-height: 1.75;
        line-break: strict;
        @include mq.mq-down(sm){
            font-size: 0.875rem;
        }
    }

    p + p, li + li{
        margin-top: 1.5rem;
    }

    a{
        text-decoration: underline;
    }

    a + a {
        &::before{
            content: "|";
            display: inline-block;
            margin-right: 1rem;
            margin-left: 1rem;
        }
    }
    hr{
        margin: 3rem 0;
        color: #ddd;
    }
    ul{
        list-style: decimal;
        margin-top: 1.75rem;
        ul{
            list-style: upper-latin;
            padding-left: 2em;
            ul{
                list-style: lower-roman;
            }
        }
    }
}